.fileList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    border: 1px solid gray;
    display: flex;
    justify-content: left;
    margin: 1em;
    position: relative;
}

.row:hover {
    min-width: min-content;
    padding: 2em;
    border: 1px solid #2dccd3;
    display: flex;
    margin:1em;
    position: relative;
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: center;
    font-size: small;
    min-width: fit-content;
}

.card-header > button {
    margin: 1em;
    height: 1em;
    width: 1em;
}

.download-btn {
    border: 1px solid #2dccd3;
    background-color: #2dccd3;
    padding: 0.5em;
    border-radius: 10px;
}

.download-btn:hover {
    border: 1px solid #2dccd3;
    background-color: #fff;
}

.download-btn > svg {
    fill: #000;
}

.download-btn:hover > svg {
    fill: #2dccd3;
}

.roulette {
    width-max: 30%;
    display: flex;
    justify-content: center;
}

.card-header > span {
    margin: 1em;
}
.card-content{
    display: flex;
    flex-direction: row;
}
.description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.badge {
    margin: 0.25em;
}

.carousel-inner {
    height: 100% !important;
    position:relative;
}

.card-deck {
    margin-left: 0px !important;
    margin-right: 0px !important;

}

.card {
    margin: 0.5em !important;
}

.card-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 20em;
}

.card-description > div {
      display: flex;
      justify-content: space-between;
}

.tagTitle > span {
    display: block;
    white-space: nowrap;
}

.tagsSection {
    display: flex;
    flex-direction: row;
    justify-content: right;
    flex-wrap: wrap;
    width: 75%;
}

.card-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-width: fit-content;
}