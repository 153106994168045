.auth-section {
    display: flex;
    justify-content: center;
}

.auth-div {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 10px;
    padding: 2em;
    box-shadow: black;
    width: 30rem;
}

.form-field {
    display: block;
    width: 100%;
    padding: 1rem;
    border: 1px solid #dadada;
    border-radius: 7px;
    height: 3.5rem;
    font-size: 1rem;
    margin-bottom: 1em;
}

.form-field:focus {
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
}

.controls {
    margin: 1em 0em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

input[type="submit"], button {
    background-color: #2dccd5;
    border: unset;
    padding: 0.5em 2em;
    width: fit-content;
}

input[type="submit"]:disabled {
    background-color: gray;
    border: unset;
    padding: 0.5em 2em;
    width: fit-content;
}

.tip {
    position: relative;
    display: inline-block;
}

.tip .tiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

.tip:hover .tiptext {
    visibility: visible;
}

.is-invalid {
    outline: none;
    border-color: #F15353;
    box-shadow: 0 0 10px #F15353;
}

.is-invalid:focus {
    outline: none;
    border-color: #F15353;
    box-shadow: 0 0 10px #F15353;
}

.invalid-feedback {
    margin-top: -0.5rem !important;
    display: flex;
    flex-direction: column;
    color: #F15353;
}

.errorArea>div {
    height: unset !important;
}

.lookLikeALink {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}