.App {
  /*text-align: center;*/
}

.body {
  min-width: 20rem;
  margin: 0 auto;
  font: 400 1rem/1.25 Roboto, Helvetica, Arial, sans-serif;
  color: #000;
  display: flex;
  justify-content: center;
}

.banner-desc {
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin-left: 2em;
  text-align: left;
}

.banner-desc a {
  display: inline-block;
  text-decoration: none;
}

.App-logo {
  height: auto;
}

.leddar-logo {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  width: 30%;
  margin-bottom: 1em;
}

.banner-title {
  font: 700 3.75rem/1.125 Roboto, Helvetica, Arial, sans-serif;
  margin: 0 0 .3em;
  text-transform: none;
  width: fit-content;
}

.banner-title, h1, header {
  display: block;
}

.App-header {
  background-image: url(bg.png);
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-bottom: 1em;
  color: #fff;
}

.banner-title {
  font: 700 2.5rem/1.125 Roboto, Helvetica, Arial, sans-serif;
  margin: 0 0 .3em;
  color: #61dafb;
  text-transform: none;
}

.banner-title, h1, header {
  display: block;
}

.banner-content {
  width: 75%;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3em 5em 5em;
}

.banner-img {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  width: 100%;
  margin: auto;
  max-height: 500px;
}

@media screen and (max-width: 850px) {
  .banner-img {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .logo-div-desc {
    display: none;
  }
}


.banner-img>img {
  height: auto;
  display: block;
  border-style: none;
}

*, ::after, ::before, legend {
  box-sizing: border-box;
}

.App-link {
  color: #61dafb;
}

:root {
  --themeColor: #2dccd3;
  --hoverColor: #2dccd5;
  --amplify-primary-color: #2dccd3;
  --amplify-primary-tint: #2dccd3;
  --amplify-primary-shade: rgb(105, 95, 171);
}

amplify-authenticator {
  display: block;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 1em;
}

.authenticator-body {
  display: flex;
  justify-content: center;
}

.App-authenticator-header {
  background-image: url(bg.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 20vw;
  position: relative;
  margin-bottom: 1em;
  color: #fff;
}

.App-authenticator-header>.banner-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}

.App-authenticator-header .app-img {
  max-width: 15%;
  max-height: 15%;
}

.banner-desc {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.leddar-logo-app {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  width: 75%;
  margin-bottom: 2em;
}

.leddar-button {
  border-radius: 5px;
  background-color: #2dccd5;
  position: absolute;
  border: unset;
  padding: 0.5em 2em;
}

.signout {
  position: absolute;
  top: 8px;
  right: 16px;
}

.App-logo {
  width: 40rem;
  padding: 1em;
}

.download-btn {
  display: flex;
  justify-content: center;
}